import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/base';

const initialState = {
    productListPage: 1,
    productListMethod: '',
    productListCategory: { value: '', label: '分類篩選' },
    productListFilter: '',
};

const setProductListPage = (state, action) => {
    return updateObject(state, {
        productListPage: action.page,
    });
};

const setProductListMethod = (state, action) => {
    return updateObject(state, {
        productListMethod: action.method,
    });
};

const setProductListCategory = (state, action) => {
    return updateObject(state, {
        productListCategory: action.category,
    });
};

const setProductListFilter = (state, action) => {
    return updateObject(state, {
        productListFilter: action.filter,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PRODUCT_LIST_PAGE: return setProductListPage(state, action);
        case actionTypes.SET_PRODUCT_LIST_METHOD: return setProductListMethod(state, action);
        case actionTypes.SET_PRODUCT_LIST_CATEGORY: return setProductListCategory(state, action);
        case actionTypes.SET_PRODUCT_LIST_FILTER: return setProductListFilter(state, action);
        default: return state;
    };
};

export default reducer;