import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/base';

const initialState = {
    faqListPage: 1,
    faqListFilter: '',
    faqListOrderBy: 'sort ASC',
    faqListNumPerPage: 20,
};

const setFaqListPage = (state, action) => {
    return updateObject(state, {
        faqListPage: action.data,
    });
};

const setFaqListFilter = (state, action) => {
    return updateObject(state, {
        faqListFilter: action.data,
    });
};

const setFaqListOrderBy = (state, action) => {
    return updateObject(state, {
        faqListOrderBy: action.data,
    });
};

const setFaqListNumPerPage = (state, action) => {
    return updateObject(state, {
        faqListNumPerPage: action.data,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_FAQ_LIST_PAGE: return setFaqListPage(state, action);
        case actionTypes.SET_FAQ_LIST_FILTER: return setFaqListFilter(state, action);
        case actionTypes.SET_FAQ_LIST_ORDER_BY: return setFaqListOrderBy(state, action);
        case actionTypes.SET_FAQ_LIST_NUM_PER_PAGE: return setFaqListNumPerPage(state, action);
        default: return state;
    };
};

export default reducer;