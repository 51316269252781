import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/base';

const initialState = {
    categoryListExpandable: [],
};

const setCategoryListExpandable = (state, action) => {
    return updateObject(state, {
        categoryListExpandable: action.data,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CATEGORY_LIST_EXPANDABLE: return setCategoryListExpandable(state, action);
        default: return state;
    };
};

export default reducer;