import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/base';

const initialState = {
    permDataDownload: [],
    token: null,
    idUser: null,
    emailUser: null,
    isSales: null,
    isFinance: null,
    isAdmin: null,
    nameFirst: null,
    nameLast: null,
    error: null,
    loading: false,
    authRedirectPath: '/',
};

const authResetError = (state, action) => {
    return updateObject(state, {
        error: null,
    });
};

const authStart = (state, action) => {
    return updateObject(state, { 
        error: null, 
        loading: true, 
    });
};

const authSuccess = (state, action) => {
    return updateObject(state, {
        permDataDownload: action.permDataDownload,
        token: action.token,
        idUser: action.idUser,
        emailUser: action.emailUser,
        isSales: action.isSales,
        isFinance: action.isFinance,
        isAdmin: action.isAdmin,
        nameFirst: action.nameFirst,
        nameLast: action.nameLast,
        error: null,
        loading: false,
    });
};

const authFail = (state, action) => {
    return updateObject(state, {
        permDataDownload: [],
        token: null,
        idUser: null,
        emailUser: null,
        isSales: null,
        isFinance: null,
        isAdmin: null,
        nameFirst: null,
        nameLast: null,
        error: action.error,
        loading: false,
    });
};

const authLogout = (state, action) => {
    return updateObject(state, {
        permDataDownload: [],
        token: null,
        idUser: null,
        emailUser: null,
        isSales: null,
        isFinance: null,
        isAdmin: null,
        nameFirst: null,
        nameLast: null,
        error: null,
        loading: false,
    });
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, {
        authRedirectPath: action.path,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action);
        case actionTypes.AUTH_RESET_ERROR: return authResetError(state, action);
        default: return state;
    };
};

export default reducer;