import * as actionTypes from './actionTypes';

export const setDiscountPublicListPage = (data) => {
    return {
        type: actionTypes.SET_DISCOUNT_PUBLIC_LIST_PAGE,
        data: data,
    };
};

export const setDiscountPublicListFilter = (data) => {
    return {
        type: actionTypes.SET_DISCOUNT_PUBLIC_LIST_FILTER,
        data: data,
    };
};

export const setDiscountPublicListOrderBy = (data) => {
    return {
        type: actionTypes.SET_DISCOUNT_PUBLIC_LIST_ORDER_BY,
        data: data,
    };
};

export const setDiscountRebateListPage = (data) => {
    return {
        type: actionTypes.SET_DISCOUNT_REBATE_LIST_PAGE,
        data: data,
    };
};

export const setDiscountRebateListFilter = (data) => {
    return {
        type: actionTypes.SET_DISCOUNT_REBATE_LIST_FILTER,
        data: data,
    };
};

export const setDiscountRebateListOrderBy = (data) => {
    return {
        type: actionTypes.SET_DISCOUNT_REBATE_LIST_ORDER_BY,
        data: data,
    };
};
