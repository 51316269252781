import React from 'react';
import { connect } from 'react-redux';

// import classes from './Layout.module.css';

import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/UI/Footer/Footer';

const Layout = (props) => {
    return (
        <main>
            <Navbar 
                isAuthenticated={props.isAuthenticated}
                isAdmin={props.isAdmin}
                isSales={props.isSales}
                isFinance={props.isFinance}
                emailUser={props.emailUser}
                permDataDownload={props.permDataDownload}
            />
            { props.children }
            <Footer />
        </main>
    );
};

const mapStateToProps = state => {
    return {
        permDataDownload: state.auth.permDataDownload,
        isAuthenticated: state.auth.token !== null,
        isSales: state.auth.isSales,
        isFinance: state.auth.isFinance,
        isAdmin: state.auth.isAdmin,
        emailUser: state.auth.emailUser,
    };    
}

// const mapDispatchToProps = dispatch => {
//     return {
//         onTryAutoSignin: () => dispatch(actions.checkAuthState()),
//     };    
// };

export default connect(mapStateToProps)(Layout);