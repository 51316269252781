import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSitemap, faBriefcase, faTags } from '@fortawesome/free-solid-svg-icons';
import { faUser, faBuilding, faStickyNote, faHandshake } from '@fortawesome/free-regular-svg-icons';

import classes from './Navside.module.css';

import Backdrop from '../UI/Backdrop/Backdrop';
// import Category from './Category';

const Navside = (props) => {
    let history = useHistory();

    let attachedClasses = [classes.Navside, classes.Close];
    if (props.open) {
        attachedClasses = [classes.Navside, classes.Open];
    }

    const handleClickItem = (action) => {
        switch (action) {
            case 'category':
                if (window.location.href.indexOf('/category') > -1) {
                    props.onCloseClicked();
                    return;
                }
                props.onCloseClicked();
                history.push('/category');
            break;
            case 'products':
                if (window.location.href.indexOf('/products') > -1) {
                    props.onCloseClicked();
                    return;
                }
                props.onCloseClicked();
                history.push('/products');
            break;
            case 'deal':
                if (window.location.href.indexOf('/deal') > -1) {
                    props.onCloseClicked();
                    return;
                }
                props.onCloseClicked();
                history.push('/deals');
            break;
            case 'orderMgmt':
                if (window.location.href.indexOf('/orderMgmt') > -1) {
                    props.onCloseClicked();
                    return;
                }
                props.onCloseClicked();
                history.push('/orderMgmt');
            break;
            case 'clients':
                if (window.location.href.indexOf('/clients') > -1) {
                    props.onCloseClicked();
                    return;
                }
                props.onCloseClicked();
                history.push('/clients');
            break;
            case 'chat':
                if (window.location.href.indexOf('/chat') > -1) {
                    props.onCloseClicked();
                    return;
                }
                props.onCloseClicked();
                history.push('/chat');
            break;
            case 'ecom_ar':
                if (window.location.href.indexOf('/ecom_ar') > -1) {
                    props.onCloseClicked();
                    return;
                }
                props.onCloseClicked();
                history.push('/ecom_ar');
            break;
            case 'banners_desktop':
                if (window.location.href.indexOf('/banners_desktop') > -1) {
                    props.onCloseClicked();
                    return;
                }
                props.onCloseClicked();
                history.push('/banners_desktop');
            break;
            case 'banners_mobile':
                if (window.location.href.indexOf('/banners_mobile') > -1) {
                    props.onCloseClicked();
                    return;
                }
                props.onCloseClicked();
                history.push('/banners_mobile');
            break;
            case 'followUs':
                if (window.location.href.indexOf('/followUs') > -1) {
                    props.onCloseClicked();
                    return;
                }
                props.onCloseClicked();
                history.push('/followUs');
            break;
            case 'news':
                if (window.location.href.indexOf('/news') > -1) {
                    props.onCloseClicked();
                    return;
                }
                props.onCloseClicked();
                history.push('/news');
            break;
            case 'users':
                if (window.location.href.indexOf('/users') > -1) {
                    props.onCloseClicked();
                    return;
                }
                props.onCloseClicked();
                history.push('/users');
            break;
            case 'settingEcom':
                if (window.location.href.indexOf('/settingEcom') > -1) {
                    props.onCloseClicked();
                    return;
                }
                props.onCloseClicked();
                history.push('/settingEcom');
            break;
            default:
                props.onCloseClicked();
        }
    };  

    return (
        <React.Fragment>
            <Backdrop show={ props.open } clicked={ props.onCloseClicked } />
            <div className={ attachedClasses.join(' ') }>
                <div className={ classes.Contents }>
                    <div style={{ fontSize: '1.5rem', marginLeft: '1rem' }}>
                        光淙金工 - 內網
                    </div>
                    <hr/>
                    <div className={ classes.NavsideItem } onClick={ () => handleClickItem('category') }>
                        商品 - 類別管理
                    </div>
                    <div className={ classes.NavsideItem } onClick={ () => handleClickItem('products') }>
                        商品 - 內容管理
                    </div>
                    <div className={ classes.NavsideItem } onClick={ () => handleClickItem('deal') }>
                        商品 - 優惠管理
                    </div>
                    <div className={ classes.NavsideItem } onClick={ () => handleClickItem('orderMgmt') }>
                        業務 - 訂單作業管理
                    </div>
                    <div className={ classes.NavsideItem } onClick={ () => handleClickItem('clients') }>
                        業務 - 客戶資料管理
                    </div>
                    <div className={ classes.NavsideItem } onClick={ () => handleClickItem('chat') }>
                        業務 - 線上客服
                    </div>
                    <div className={ classes.NavsideItem } onClick={ () => handleClickItem('ecom_ar') }>
                        會計 - 收款作業
                    </div>
                    <div className={ classes.NavsideItem } onClick={ () => handleClickItem('banners_desktop') }>
                        購物網 - 首頁 Banner (桌機寬圖)
                    </div>
                    <div className={ classes.NavsideItem } onClick={ () => handleClickItem('banners_mobile') }>
                        購物網 - 首頁 Banner (手機方圖)
                    </div>
                    <div className={ classes.NavsideItem } onClick={ () => handleClickItem('followUs') }>
                        購物網 - 首頁 關注我們
                    </div>
                    <div className={ classes.NavsideItem } onClick={ () => handleClickItem('news') }>
                        購物網 - 技術分享
                    </div>
                    { props.isAdmin ? (
                        <React.Fragment>
                        <div className={ classes.NavsideItem } onClick={ () => handleClickItem('users') }>
                            管理 - 使用者
                        </div>
                        <div className={ classes.NavsideItem } onClick={ () => handleClickItem('settingEcom') }>
                            管理 - 購物網
                        </div>
                        </React.Fragment>
                    ) : null }
                </div>
            </div>
        </React.Fragment>
    );
};

export default Navside;