import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/base';

const initialState = {
    userListPage: 1,
    userListFilter: '',
};

const setUserListPage = (state, action) => {
    return updateObject(state, {
        userListPage: action.page,
    });
};

const setUserListFilter = (state, action) => {
    return updateObject(state, {
        userListFilter: action.filter,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER_LIST_PAGE: return setUserListPage(state, action);
        case actionTypes.SET_USER_LIST_FILTER: return setUserListFilter(state, action);
        default: return state;
    };
};

export default reducer;