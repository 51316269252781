import axios from 'axios';
import { DateTime } from 'luxon';

import { urlHostRest } from './url';

export const convertCamelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const filter_in_allowed_chars = (allowed_chars, input) => {
    let arr = input.split('');
    for (let a = 0; a < arr.length; a++) {
        if (allowed_chars.indexOf(arr[a]) < 0) {
            arr.splice(Number(a), 1);
            a--;
        }
    }
    return arr.join('');
};

// str vars need to be in ISO format ie. yyyy-mm-ddThh:mm:ss.mmm{zone}
export const convertTimeISOStrTimezone = (timeStr, timezone) => {
    let timeObj = DateTime.fromISO(timeStr);
    let timeObjNew = timeObj.setZone(timezone);
    return timeObjNew.toString();
};

// input has to be a date
export const display_yyyy_mm_dd_hh_mm = (input) => {
    let return_list = [];
    if (input) {
        return_list.push(input.getFullYear());
        return_list.push(("0" + (input.getMonth() + 1)).slice(-2));
        return_list.push(("0" + input.getDate()).slice(-2));
        return_list.push(("0" + input.getHours()).slice(-2));
        return_list.push(("0" + input.getMinutes()).slice(-2));
        return_list.push(("0" + input.getSeconds()).slice(-2));    
    }
    return return_list[0] + '-' + return_list[1] + '-' + return_list[2] + ' ' + return_list[3] + ':' + return_list[4];
}

// input has to be a date
export const display_yyyy_mm_dd_hh_mm_ss = (input) => {
    let return_list = [];
    if (input) {
        return_list.push(input.getFullYear());
        return_list.push(("0" + (input.getMonth() + 1)).slice(-2));
        return_list.push(("0" + input.getDate()).slice(-2));
        return_list.push(("0" + input.getHours()).slice(-2));
        return_list.push(("0" + input.getMinutes()).slice(-2));
        return_list.push(("0" + input.getSeconds()).slice(-2));    
    }
    return return_list[0] + '-' + return_list[1] + '-' + return_list[2] + ' ' + return_list[3] + ':' + return_list[4] + ':' + return_list[5];
}

export const check_valid_id_tax_business_tw = (input) => {
    if (isNaN(input) || input.length !== 8) {
        return false;
    }
    return true;
};

export const check_valid_phone_mail_tw = (input) => {
    // refer to https://zh.wikipedia.org/wiki/中華民國長途電話區號表
    switch (input.substr(0, 2)) {
        case '02':
            if (input.length !== 10) {
                return false;
            }
        break;
        case '03':
        case '05':
        case '06':
        case '07':
        case '08':
            if (input.length !== 9) {
                return false;
            }
        break;
        case '04':
            if (input.substr(0, 3) === '043') {
                if (input.length !== 10) {
                    return false;
                }    
            }
            else {
                if (input.length !== 9) {
                    return false;
                }
            }
        break;
        default:
            return false;
        }
        return true;
};

export const check_valid_phone_mobile_tw = (input) => {
    if (input.indexOf('09') !== 0 || input.length !== 10) {
        return false;
    }
    return true;
};

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties,
    };
};

export const axiosHttp = async (method, url, tokenAuth, data, responseType = false) => {
    let headers = {};
    headers['Content-Type'] = 'application/json';
    if (tokenAuth) {
        headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    }

    let axios_obj = {
        method: method,
        url: urlHostRest + url,
        headers: headers,
        data: data,
    };
    if (responseType) {
        axios_obj['responseType'] = responseType;
    }
    return axios(axios_obj)
    .then(res => {
        return res;
    })
    .catch(err => {
        axiosCatch(err);
    });    
};

export const axiosCatch = (err, msg) => {
    console.log(err);
    console.log(msg);
    // console.log(err.response.data.message);
    try {
        if (err.response.data.message === 'jwt expired' || err.response.data.message === 'jwt malformed') {
            // alert('您的登入時效已過期，請重新登入');
            window.location.href = '/logout';
        }
        else if (err.response.data.status === 500) {
            alert('ERROR ' + err.response.data.status + " : " + err.response.data.message);
        }
        else if (err.response.data.status === 422) {
            let message = err.response.data.message + '\n';
            for (var key in err.response.data.data) {
                message += '  ' + err.response.data.data[key].msg + '\n';
            }
            alert('ERROR ' + err.response.data.status + " :\n" + message)
        }
    }
    catch {
        window.location = '/logout';
    };
};

export const numberWithCommas = (x) => {
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export const convertDateToList = (INPUT_DATE) => {
    let RETURN_LIST = [];
    if (INPUT_DATE) {
        RETURN_LIST.push(INPUT_DATE.getFullYear());
        RETURN_LIST.push(("0" + (INPUT_DATE.getMonth() + 1)).slice(-2));
        RETURN_LIST.push(("0" + INPUT_DATE.getDate()).slice(-2));
        RETURN_LIST.push(("0" + INPUT_DATE.getHours()).slice(-2));
        RETURN_LIST.push(("0" + INPUT_DATE.getMinutes()).slice(-2));
        RETURN_LIST.push(("0" + INPUT_DATE.getSeconds()).slice(-2));    
    }
    return RETURN_LIST;
}

export const convertStringToDate = (INPUT_STR) => {
    if (INPUT_STR) {
        let INPUT_LIST = INPUT_STR.split('-');
        return new Date(INPUT_LIST[0], Number(INPUT_LIST[1]) - 1, INPUT_LIST[2]);    
    }
}

export const convertStringToDateUTC = (INPUT_STR) => {
    if (INPUT_STR) {
        let INPUT_LIST = INPUT_STR.split('-');
        return new Date(Date.UTC(INPUT_LIST[0], Number(INPUT_LIST[1]) - 1, INPUT_LIST[2]));    
    }
}

export const parseUTCStrToLocalStr = (strUTC) => {
    let [dateFull, timeFull] = strUTC.split(' ');
    let dateYear = typeof dateFull.split('-')[0] === 'undefined' ? '' : dateFull.split('-')[0];
    let dateMonth = typeof dateFull.split('-')[1] === 'undefined' ? '' : parseInt(dateFull.split('-')[1]) - 1;
    let dateDay = typeof dateFull.split('-')[2] === 'undefined' ? '' : dateFull.split('-')[2];
    let timeHour = typeof timeFull === 'undefined' ? '' : timeFull.split(':')[0];
    let timeMinute = typeof timeFull === 'undefined' ? '' : timeFull.split(':')[1];
    let timeSecond = typeof timeFull === 'undefined' ? '' : timeFull.split(':')[2];
    let dateLocal = new Date(Date.UTC(dateYear, dateMonth, dateDay, timeHour, timeMinute, timeSecond));
    let listLocal = convertDateToList(dateLocal);
    return listLocal[0] + '-' + listLocal[1] + '-' + listLocal[2] + ' ' + listLocal[3] + ':' + listLocal[4] + ':' + listLocal[5];
};

export const nameVendorFromIdVendor = (idVendor, listVendor) => {
    for (let a in listVendor) {
        if (listVendor[a].idVendor.toString() === idVendor.toString()) {
            return {
                'zh': listVendor[a].nameVendorZh,
                'en': listVendor[a].nameVendorEn,
            };
        }
    }
};

export const addMonthsToStr = (inputStr, numMonths) => {
    let time = new Date(inputStr);
    time.setMonth(time.getMonth() + Number(numMonths));

    return time.getFullYear() + "-" + ('0' + (time.getMonth() + 1)).slice(-2) + "-" + ('0' + time.getDate()).slice(-2) + " " + ('0' + time.getHours()).slice(-2) + ":" + ('0' + time.getMinutes()).slice(-2) + ":" + ('0' + time.getSeconds()).slice(-2);
};

export const addMonthsToTime = (inputTime, numMonths) => {
    let time = inputTime;
    time.setMonth(time.getMonth() + Number(numMonths));

    return time;
};

export const addDaysToTime = (inputTime, numDays) => {
    let time = inputTime;
    time.setDate(time.getDate() + Number(numDays));

    return time;
};