import * as actionTypes from './actionTypes';

export const setFaqListPage = (data) => {
    return {
        type: actionTypes.SET_FAQ_LIST_PAGE,
        data: data,
    };
};

export const setFaqListFilter = (data) => {
    return {
        type: actionTypes.SET_FAQ_LIST_FILTER,
        data: data,
    };
};

export const setFaqListOrderBy = (data) => {
    return {
        type: actionTypes.SET_FAQ_LIST_ORDER_BY,
        data: data,
    };
};

export const setFaqListNumPerPage = (data) => {
    return {
        type: actionTypes.SET_FAQ_LIST_NUM_PER_PAGE,
        data: data,
    };
};
