// props.isAuthenticated={ props.isAuthenticated }
// props.isAdmin={ props.isAdmin }
// props.emailUser={ props.emailUser }

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faBars } from '@fortawesome/free-solid-svg-icons';

import classes from './Navbar.module.css';

import Navside from '../Navside/Navside';
import { urlBaseImgStatic } from '../../helpers/url';

const Navbar = (props) => {
    const [stateViewDrawer, setViewDrawer] = useState(false);
    
    let divClass = classes.hideNavbar;
    if (props.isAuthenticated) {
        divClass = classes.showNavbar;
    }

    const handleDrawerToggle = () => {
        setViewDrawer((prev) => { 
            return !prev;
        });
    };

    let menuAdmin = null;
    if (props.isAdmin) {
        menuAdmin = (
            <ul className={ classes.Menu }>
                <li className={ classes.MenuItem }>
                    管理／設定
                    <ul className={ classes.Submenu }>
                        <NavLink to="/users">
                            <li className={ classes.SubmenuItem }>使用者管理</li>
                        </NavLink>
                        <NavLink to="/settingEcom">
                            <li className={ classes.SubmenuItem }>購物網設定</li>
                        </NavLink>
                    </ul>
                </li>
            </ul>
        );
    }

    return (
        <React.Fragment>
        <Navside 
            open={stateViewDrawer}
            onCloseClicked={handleDrawerToggle}
            isAdmin={props.isAdmin}
        />
        <div className={divClass}>
            <nav className={classes.Navbar}>
                <NavLink to='/'>
                    <img src={urlBaseImgStatic + "logo/wnw2-logo-04-trans-white.png"} alt="Goodsnlogo Logo" style={{height:'35px', marginRight:'10px', marginTop:'3px'}} />
                </NavLink>

                {(props.isAdmin || props.isSales) 
                ? (
                    <ul className={ classes.Menu }>
                        <li className={ classes.MenuItem }>
                            商品
                            <ul className={ classes.Submenu }>
                                <NavLink to="/categorys">
                                    <li className={ classes.SubmenuItem }>類別管理</li>
                                </NavLink>
                                <NavLink to="/products">
                                    <li className={ classes.SubmenuItem }>商品管理</li>
                                </NavLink>
                                {/* <NavLink to="/deals">
                                    <li className={ classes.SubmenuItem }>優惠管理</li>
                                </NavLink> */}
                                <NavLink to="/inv_upload">
                                    <li className={ classes.SubmenuItem }>庫存數量上傳</li>
                                </NavLink>
                            </ul>
                        </li>
                    </ul>
                ) 
                : null}

                {(props.isAdmin || props.isSales) 
                ? (
                    <ul className={ classes.Menu }>
                        <li className={ classes.MenuItem }>
                            業務
                            <ul className={ classes.Submenu }>
                                <NavLink to="/orders">
                                    <li className={ classes.SubmenuItem }>訂單 - 作業管理</li>
                                </NavLink>
                                {/* <NavLink to="/order_total_only">
                                    <li className={ classes.SubmenuItem }>訂單 - 現場購物總額</li>
                                </NavLink> */}
                                {/* <NavLink to="/order_custom">
                                    <li className={ classes.SubmenuItem }>訂單 - 客製訂單</li>
                                </NavLink> */}
                                <NavLink to="/clients">
                                    <li className={ classes.SubmenuItem }>客戶 - 資料管理</li>
                                </NavLink>
                                {/* <NavLink to="/edm_mailing_lists">
                                    <li className={ classes.SubmenuItem }>EDM - Mailing List</li>
                                </NavLink> */}
                                {/* <NavLink to="/edms">
                                    <li className={ classes.SubmenuItem }>EDM - 作業管理</li>
                                </NavLink> */}
                                {/* <NavLink to="/discount_public">
                                    <li className={ classes.SubmenuItem }>折扣碼 - 大眾型</li>
                                </NavLink> */}
                                {/* <NavLink to="/discount_rebate">
                                    <li className={ classes.SubmenuItem }>折扣碼 - 回饋型</li>
                                </NavLink> */}
                                {/* <NavLink to="/chat">
                                    <li className={ classes.SubmenuItem }>線上客服</li>
                                </NavLink> */}
                            </ul>
                        </li>
                    </ul>
                ) 
                : null}

                {(props.isAdmin || props.isFinance) 
                ? (
                    <ul className={ classes.Menu }>
                        <li className={ classes.MenuItem }>
                            會計
                            <ul className={ classes.Submenu }>
                                <NavLink to="/ecom_ar">
                                    <li className={ classes.SubmenuItem }>收款作業</li>
                                </NavLink>
                                <NavLink to="/ecom_ap">
                                    <li className={ classes.SubmenuItem }>退款作業</li>
                                </NavLink>
                                <NavLink to="/sinopac_cc_check">
                                    <li className={ classes.SubmenuItem }>信用卡交易檢查</li>
                                </NavLink>
                            </ul>
                        </li>
                    </ul>
                ) 
                : null}

                {(props.permDataDownload.length > 0) 
                ? (
                    <ul className={classes.Menu}>
                        <li className={classes.MenuItem}>
                            資料
                            <ul className={classes.Submenu}>
                                <NavLink to="/data_download">
                                    <li className={classes.SubmenuItem}>匯出</li>
                                </NavLink>
                            </ul>
                        </li>
                    </ul>
                ) 
                : null}

                {(props.isAdmin || props.isSales) 
                ? (
                    <ul className={ classes.Menu }>
                        <li className={ classes.MenuItem }>
                            購物網
                            <ul className={classes.Submenu}>
                                <NavLink to="/banners_desktop">
                                    <li className={classes.SubmenuItem} style={{width:'200px'}}>首頁 - Banner (桌機寬圖)</li>
                                </NavLink>
                                <NavLink to="/banners_mobile">
                                    <li className={classes.SubmenuItem} style={{width:'200px'}}>首頁 - Banner (手機方圖)</li>
                                </NavLink>
                                {/* <NavLink to="/followUs">
                                    <li className={classes.SubmenuItem} style={{width:'200px'}}>首頁 - 關注我們</li>
                                </NavLink> */}
                                {/* <NavLink to="/news">
                                    <li className={classes.SubmenuItem} style={{width:'200px'}}>技術分享</li>
                                </NavLink> */}
                                {/* <NavLink to="/faqs">
                                    <li className={classes.SubmenuItem} style={{width:'200px'}}>FAQ</li>
                                </NavLink> */}
                                {/* <NavLink to="/ecom_about_us">
                                    <li className={classes.SubmenuItem} style={{width:'200px'}}>關於我們</li>
                                </NavLink> */}
                                <NavLink to="/ecom_shipping_policy">
                                    <li className={classes.SubmenuItem} style={{width:'200px'}}>Shipping Policy</li>
                                </NavLink>
                                <NavLink to="/ecom_return_policy">
                                    <li className={classes.SubmenuItem} style={{width:'200px'}}>Return Policy</li>
                                </NavLink>
                                <NavLink to="/ecom_privacy_policy">
                                    <li className={classes.SubmenuItem} style={{width:'200px'}}>Privacy Policy</li>
                                </NavLink>
                                {/* <NavLink to="/ecom_vip_policy">
                                    <li className={classes.SubmenuItem} style={{width:'200px'}}>VIP升等辦法</li>
                                </NavLink> */}
                                {/* <NavLink to="/ecom_register_policy">
                                    <li className={classes.SubmenuItem} style={{width:'200px'}}>註冊會員條款</li>
                                </NavLink> */}
                            </ul>
                        </li>
                    </ul>
                ) 
                : null}

                { menuAdmin }

                <ul className={ classes.Menu } style={{ marginLeft: 'auto' }}>
                    <li className={ classes.MenuItemRight }>
                        <FontAwesomeIcon icon={ faUserCircle } size="2x" />&nbsp;{ props.emailUser === null ? null : props.emailUser.split('@')[0] }
                        <ul className={ classes.Submenu }>
                            <NavLink to="/userDetail">
                                <li className={ classes.SubmenuItemRight }>基本資料</li>
                            </NavLink>
                            {/* <NavLink to="/changeUserPw">
                                <li className={ classes.SubmenuItemRight }>更改密碼</li>                                
                            </NavLink> */}
                            <NavLink to="/logout">
                                <li className={ classes.SubmenuItemRight }>登出</li>
                            </NavLink>
                        </ul>
                    </li>
                </ul>               
            </nav>
            <nav className={ classes.NavbarMobile }>
                <FontAwesomeIcon icon={ faBars } size="2x" style={{ color: 'white', marginTop: '0.5rem' }} onClick={ handleDrawerToggle } />
                {/* <NavItems drawerToggleClicked={ props.drawerToggleClicked } /> */}
                <NavLink to='/'>
                    <div style={{ height: '40px', marginTop: '0.1rem', marginLeft: 'auto' }}><img src={ urlBaseImgStatic + "logo/WW_340x340.png" } alt="Logo" height="100%" /></div>
                </NavLink>
            </nav>
        </div>
        </React.Fragment>
    );
};

export default Navbar;