import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/base';

const initialState = {
    discountPublicListPage: 1,
    discountPublicListFilter: '',
    discountPublicListOrderBy: 'dateEnd DESC',
    discountRebateListPage: 1,
    discountRebateListFilter: '',
    discountRebateListOrderBy: 'dateEnd DESC',
};

const setDiscountPublicListPage = (state, action) => {
    return updateObject(state, {
        discountPublicListPage: action.data,
    });
};

const setDiscountPublicListFilter = (state, action) => {
    return updateObject(state, {
        discountPublicListFilter: action.data,
    });
};

const setDiscountPublicListOrderBy = (state, action) => {
    return updateObject(state, {
        discountPublicListOrderBy: action.data,
    });
};

const setDiscountRebateListPage = (state, action) => {
    return updateObject(state, {
        discountRebateListPage: action.data,
    });
};

const setDiscountRebateListFilter = (state, action) => {
    return updateObject(state, {
        discountRebateListFilter: action.data,
    });
};

const setDiscountRebateListOrderBy = (state, action) => {
    return updateObject(state, {
        discountRebateListOrderBy: action.data,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_DISCOUNT_PUBLIC_LIST_PAGE: return setDiscountPublicListPage(state, action);
        case actionTypes.SET_DISCOUNT_PUBLIC_LIST_FILTER: return setDiscountPublicListFilter(state, action);
        case actionTypes.SET_DISCOUNT_PUBLIC_LIST_ORDER_BY: return setDiscountPublicListOrderBy(state, action);
        case actionTypes.SET_DISCOUNT_REBATE_LIST_PAGE: return setDiscountRebateListPage(state, action);
        case actionTypes.SET_DISCOUNT_REBATE_LIST_FILTER: return setDiscountRebateListFilter(state, action);
        case actionTypes.SET_DISCOUNT_REBATE_LIST_ORDER_BY: return setDiscountRebateListOrderBy(state, action);
        default: return state;
    };
};

export default reducer;