import * as actionTypes from './actionTypes';

export const setClientListPage = (page) => {
    return {
        type: actionTypes.SET_CLIENT_LIST_PAGE,
        page: page,
    };
};

export const setClientListFilter = (filter) => {
    return {
        type: actionTypes.SET_CLIENT_LIST_FILTER,
        filter: filter,
    };
};

export const setClientListOrderBy = (orderBy) => {
    return {
        type: actionTypes.SET_CLIENT_LIST_ORDER_BY,
        orderBy: orderBy,
    };
};
