import * as actionTypes from './actionTypes';

export const setUserListPage = (page) => {
    return {
        type: actionTypes.SET_USER_LIST_PAGE,
        page: page,
    };
};

export const setUserListFilter = (filter) => {
    return {
        type: actionTypes.SET_USER_LIST_FILTER,
        filter: filter,
    };
};
