import * as actionTypes from './actionTypes';

export const setOrderListPage = (page) => {
    return {
        type: actionTypes.SET_ORDER_LIST_PAGE,
        page: page
    };
};

export const setOrderListQuery = (data) => {
    return {
        type: actionTypes.SET_ORDER_LIST_QUERY,
        data: data
    };
};

export const setOrderListStatus = (data) => {
    return {
        type: actionTypes.SET_ORDER_LIST_STATUS,
        data: data
    };
};

export const setOrderListNumPerPage = (data) => {
    return {
        type: actionTypes.SET_ORDER_LIST_NUM_PER_PAGE,
        data: data
    };
};

export const setRecvEcomListPage = (page) => {
    return {
        type: actionTypes.SET_RECV_ECOM_LIST_PAGE,
        page: page
    };
};

export const setRecvEcomListDisplay = (display) => {
    return {
        type: actionTypes.SET_RECV_ECOM_LIST_DISPLAY,
        display: display
    };
};

export const setRecvEcomListIsPaidFull = (timePaidFull) => {
    return {
        type: actionTypes.SET_RECV_ECOM_LIST_IS_PAID_FULL,
        timePaidFull: timePaidFull
    };
};

export const setRecvEcomListQuery = (query) => {
    return {
        type: actionTypes.SET_RECV_ECOM_LIST_QUERY,
        query: query
    };
};

export const setOrderCustomPage = (page) => {
    return {
        type: actionTypes.SET_ORDER_CUSTOM_PAGE,
        page: page,
    };
};

export const setOrderCustomMethod = (method) => {
    return {
        type: actionTypes.SET_ORDER_CUSTOM_METHOD,
        method: method,
    };
};

export const setOrderCustomCategory = (category) => {
    return {
        type: actionTypes.SET_ORDER_CUSTOM_CATEGORY,
        category: category,
    };
};

export const setOrderCustomFilter = (filter) => {
    return {
        type: actionTypes.SET_ORDER_CUSTOM_FILTER,
        filter: filter,
    };
};

export const setOrderCustomCart = (cart) => {
    return {
        type: actionTypes.SET_ORDER_CUSTOM_CART,
        cart: cart,
    };
};

export const setPayEcomListPage = (data) => {
    return {
        type: actionTypes.SET_PAY_ECOM_LIST_PAGE,
        data: data
    };
};

export const setPayEcomListQuery = (data) => {
    return {
        type: actionTypes.SET_PAY_ECOM_LIST_QUERY,
        data: data
    };
};

export const setPayEcomListDisplay = (data) => {
    return {
        type: actionTypes.SET_PAY_ECOM_LIST_DISPLAY,
        data: data
    };
};

export const setPayEcomListStatus = (data) => {
    return {
        type: actionTypes.SET_PAY_ECOM_LIST_STATUS,
        data: data
    };
};
