import * as actionTypes from './actionTypes';

export const setProductListPage = (page) => {
    return {
        type: actionTypes.SET_PRODUCT_LIST_PAGE,
        page: page,
    };
};

export const setProductListMethod = (method) => {
    return {
        type: actionTypes.SET_PRODUCT_LIST_METHOD,
        method: method,
    };
};

export const setProductListCategory = (category) => {
    return {
        type: actionTypes.SET_PRODUCT_LIST_CATEGORY,
        category: category,
    };
};

export const setProductListFilter = (filter) => {
    return {
        type: actionTypes.SET_PRODUCT_LIST_FILTER,
        filter: filter,
    };
};
