import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/base';

const initialState = {
    clientListPage: 1,
    clientListFilter: '',
    clientListOrderBy: 'createdOn DESC',
};

const setClientListPage = (state, action) => {
    return updateObject(state, {
        clientListPage: action.page,
    });
};

const setClientListFilter = (state, action) => {
    return updateObject(state, {
        clientListFilter: action.filter,
    });
};

const setClientListOrderBy = (state, action) => {
    return updateObject(state, {
        clientListOrderBy: action.orderBy,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CLIENT_LIST_PAGE: return setClientListPage(state, action);
        case actionTypes.SET_CLIENT_LIST_FILTER: return setClientListFilter(state, action);
        case actionTypes.SET_CLIENT_LIST_ORDER_BY: return setClientListOrderBy(state, action);
        default: return state;
    };
};

export default reducer;