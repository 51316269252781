import jwtDecode from 'jwt-decode';
import axios from 'axios';

import * as actionTypes from './actionTypes';
import { urlHostRest } from '../../helpers/url';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START,
    };
};

export const authSuccess = (permDataDownload, token, idUser, emailUser, isSales, isFinance, isAdmin, nameFirst, nameLast) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        permDataDownload: permDataDownload,
        token: token,
        idUser: idUser,
        emailUser: emailUser,
        isSales: isSales === 1,
        isFinance: isFinance === 1,
        isAdmin: isAdmin === 1,
        nameFirst: nameFirst,
        nameLast: nameLast,
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error,
    };
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('idUser');
    return {
        type: actionTypes.AUTH_LOGOUT,
    };
};

export const setAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            // alert('您的登入時效已過期，請重新登入');
            dispatch(logout());
        }, Number(expirationTime));
    };
};

export const login = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        axios({
            method: 'post',
            url: urlHostRest + '/user/login',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                email: email,
                password: password,
            }
        })
        .then(res => {
            let tokenDecoded = jwtDecode(res.data.token);
            if (tokenDecoded.isStaff === 1) {
                localStorage.setItem('token', res.data.token);
                dispatch(authSuccess(tokenDecoded.permDataDownload, res.data.token, tokenDecoded.id, tokenDecoded.email, tokenDecoded.isSales, tokenDecoded.isFinance, tokenDecoded.isAdmin, tokenDecoded.nameFirst, tokenDecoded.nameLast));
                dispatch(setAuthTimeout(tokenDecoded.expiresIn));
                return;
            }
            dispatch(authFail('USER_NOT_STAFF'));
        })
        .catch(err => {
            try {
                dispatch(authFail(err.response.data.message));
            }
            catch {
                window.location = '/logout';
            };
        });
    }
};

export const setAuthRedirectPath = (path) => {
    if (path == null) {
        path = window.location.pathname;
    }
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path,
    };
};

export const checkAuthState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        }
        else {
            let tokenDecoded = jwtDecode(token);
            const expirationTime = tokenDecoded.expirationTime;
            if (new Date(expirationTime) > new Date()) {
                const idUser = tokenDecoded.id;
                dispatch(authSuccess(tokenDecoded.permDataDownload, token, idUser, tokenDecoded.email, tokenDecoded.isSales, tokenDecoded.isFinance, tokenDecoded.isAdmin, tokenDecoded.nameFirst, tokenDecoded.nameLast));
            }
            else {
                dispatch(logout());
            }
        }
    };
};

export const authResetError = () => {
    return {
        type: actionTypes.AUTH_RESET_ERROR,
    };
};