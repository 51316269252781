import React from 'react';

import classes from './Spinner.module.css';

const spinner = (props) => {
    let borderLeft = props.back === 'white' ? '1.1em solid #FFFFFF' : '1.1em solid #64FFDA';
    return (
        <div className={ classes.Container }>
            <span className={ classes.Loader } style={{ borderLeft: borderLeft }}></span>
            <span className={ classes.Text }>處理中...</span>
        </div>
    );
};

export default spinner;