export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';

export const SET_CLIENT_LIST_PAGE = 'SET_CLIENT_LIST_PAGE';
export const SET_CLIENT_LIST_FILTER = 'SET_CLIENT_LIST_FILTER';
export const SET_CLIENT_LIST_ORDER_BY = 'SET_CLIENT_LIST_ORDER_BY';

export const SET_MAILING_LIST_PAGE = 'SET_MAILING_LIST_PAGE';
export const SET_MAILING_LIST_FILTER = 'SET_MAILING_LIST_FILTER';
export const SET_MAILING_LIST_ORDER_BY = 'SET_MAILING_LIST_ORDER_BY';
export const SET_MAILING_LIST_NUM_PER_PAGE = 'SET_MAILING_LIST_NUM_PER_PAGE';
export const SET_EDM_LIST_PAGE = 'SET_EDM_LIST_PAGE';
export const SET_EDM_LIST_FILTER = 'SET_EDM_LIST_FILTER';
export const SET_EDM_LIST_ORDER_BY = 'SET_EDM_LIST_ORDER_BY';
export const SET_EDM_LIST_NUM_PER_PAGE = 'SET_EDM_LIST_NUM_PER_PAGE';

export const SET_FAQ_LIST_PAGE = 'SET_FAQ_LIST_PAGE';
export const SET_FAQ_LIST_FILTER = 'SET_FAQ_LIST_FILTER';
export const SET_FAQ_LIST_ORDER_BY = 'SET_FAQ_LIST_ORDER_BY';
export const SET_FAQ_LIST_NUM_PER_PAGE = 'SET_FAQ_LIST_NUM_PER_PAGE';

export const SET_DISCOUNT_PUBLIC_LIST_PAGE = 'SET_DISCOUNT_PUBLIC_LIST_PAGE';
export const SET_DISCOUNT_PUBLIC_LIST_FILTER = 'SET_DISCOUNT_PUBLIC_LIST_FILTER';
export const SET_DISCOUNT_PUBLIC_LIST_ORDER_BY = 'SET_DISCOUNT_PUBLIC_LIST_ORDER_BY';
export const SET_DISCOUNT_REBATE_LIST_PAGE = 'SET_DISCOUNT_REBATE_LIST_PAGE';
export const SET_DISCOUNT_REBATE_LIST_FILTER = 'SET_DISCOUNT_REBATE_LIST_FILTER';
export const SET_DISCOUNT_REBATE_LIST_ORDER_BY = 'SET_DISCOUNT_REBATE_LIST_ORDER_BY';

export const SET_NEWS_LIST_PAGE = 'SET_NEWS_LIST_PAGE';
export const SET_NEWS_LIST_QUERY = 'SET_NEWS_LIST_QUERY';
export const SET_NEWS_LIST_NUM_PER_PAGE = 'SET_NEWS_LIST_NUM_PER_PAGE';

export const SET_USER_LIST_PAGE = 'SET_USER_LIST_PAGE';
export const SET_USER_LIST_FILTER = 'SET_USER_LIST_FILTER';

export const SET_ORDER_LIST_PAGE = 'SET_ORDER_LIST_PAGE';
export const SET_ORDER_LIST_QUERY = 'SET_ORDER_LIST_QUERY';
export const SET_ORDER_LIST_STATUS = 'SET_ORDER_LIST_STATUS';
export const SET_ORDER_LIST_NUM_PER_PAGE = 'SET_ORDER_LIST_NUM_PER_PAGE';
export const SET_RECV_ECOM_LIST_PAGE = 'SET_RECV_ECOM_LIST_PAGE';
export const SET_RECV_ECOM_LIST_DISPLAY = 'SET_RECV_ECOM_LIST_DISPLAY';
export const SET_RECV_ECOM_LIST_IS_PAID_FULL = 'SET_RECV_ECOM_LIST_IS_PAID_FULL';
export const SET_RECV_ECOM_LIST_QUERY = 'SET_RECV_ECOM_LIST_QUERY';
export const SET_ORDER_CUSTOM_PAGE = 'SET_ORDER_CUSTOM_PAGE';
export const SET_ORDER_CUSTOM_METHOD = 'SET_ORDER_CUSTOM_METHOD';
export const SET_ORDER_CUSTOM_CATEGORY = 'SET_ORDER_CUSTOM_CATEGORY';
export const SET_ORDER_CUSTOM_FILTER = 'SET_ORDER_CUSTOM_FILTER';
export const SET_ORDER_CUSTOM_CART = 'SET_ORDER_CUSTOM_CART';
export const SET_PAY_ECOM_LIST_PAGE = 'SET_PAY_ECOM_LIST_PAGE';
export const SET_PAY_ECOM_LIST_QUERY = 'SET_PAY_ECOM_LIST_QUERY';
export const SET_PAY_ECOM_LIST_DISPLAY = 'SET_PAY_ECOM_LIST_DISPLAY';
export const SET_PAY_ECOM_LIST_STATUS = 'SET_PAY_ECOM_LIST_STATUS';

export const SET_PRODUCT_LIST_PAGE = 'SET_PRODUCT_LIST_PAGE';
export const SET_PRODUCT_LIST_METHOD = 'SET_PRODUCT_LIST_METHOD';
export const SET_PRODUCT_LIST_CATEGORY = 'SET_PRODUCT_LIST_CATEGORY';
export const SET_PRODUCT_LIST_FILTER = 'SET_PRODUCT_LIST_FILTER';

export const SET_CATEGORY_LIST_EXPANDABLE = 'SET_CATEGORY_LIST_EXPANDABLE';
