import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/base';

const initialState = {
    orderListPage: 1,
    orderListQuery: '',
    orderListStatus: 'pending',
    orderListNumPerPage: 20,
    recvEcomListPage: 1,
    recvEcomListDisplay: 'atm',
    recvEcomListIsPaidFull: 0,
    recvEcomListQuery: '',
    orderCustomPage: 1,
    orderCustomMethod: '',
    orderCustomCategory: { value: '', label: '分類篩選' },
    orderCustomFilter: '',
    orderCustomCart: [],
    payEcomListPage: 1,
    payEcomListQuery: '',
    payEcomListDisplay: 'atm',
    payEcomListStatus: 'pending',
};

const setOrderListPage = (state, action) => {
    return updateObject(state, {
        orderListPage: action.page
    });
};

const setOrderListQuery = (state, action) => {
    return updateObject(state, {
        orderListQuery: action.data
    });
};

const setOrderListStatus = (state, action) => {
    return updateObject(state, {
        orderListStatus: action.data
    });
};

const setOrderListNumPerPage = (state, action) => {
    return updateObject(state, {
        orderListNumPerPage: action.data
    });
};

const setRecvEcomListPage = (state, action) => {
    return updateObject(state, {
        recvEcomListPage: action.page
    })
};

const setRecvEcomListDisplay = (state, action) => {
    return updateObject(state, {
        recvEcomListDisplay: action.display
    })
};

const setRecvEcomListIsPaidFull = (state, action) => {
    return updateObject(state, {
        recvEcomListIsPaidFull: action.timePaidFull
    })
};

const setRecvEcomListQuery = (state, action) => {
    return updateObject(state, {
        recvEcomListQuery: action.query
    })
};

const setOrderCustomPage = (state, action) => {
    return updateObject(state, {
        orderCustomPage: action.page,
    });
};

const setOrderCustomMethod = (state, action) => {
    return updateObject(state, {
        orderCustomMethod: action.method,
    });
};

const setOrderCustomCategory = (state, action) => {
    return updateObject(state, {
        orderCustomCategory: action.category,
    });
};

const setOrderCustomFilter = (state, action) => {
    return updateObject(state, {
        orderCustomFilter: action.filter,
    });
};

const setOrderCustomCart = (state, action) => {
    return updateObject(state, {
        orderCustomCart: action.cart,
    });
};

const setPayEcomListPage = (state, action) => {
    return updateObject(state, {
        payEcomListPage: action.data
    })
};

const setPayEcomListQuery = (state, action) => {
    return updateObject(state, {
        payEcomListQuery: action.data
    })
};

const setPayEcomListDisplay = (state, action) => {
    return updateObject(state, {
        payEcomListDisplay: action.data
    })
};

const setPayEcomListStatus = (state, action) => {
    return updateObject(state, {
        payEcomListStatus: action.data
    })
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ORDER_LIST_PAGE: return setOrderListPage(state, action);
        case actionTypes.SET_ORDER_LIST_QUERY: return setOrderListQuery(state, action);
        case actionTypes.SET_ORDER_LIST_STATUS: return setOrderListStatus(state, action);
        case actionTypes.SET_ORDER_LIST_NUM_PER_PAGE: return setOrderListNumPerPage(state, action);
        case actionTypes.SET_RECV_ECOM_LIST_PAGE: return setRecvEcomListPage(state, action);
        case actionTypes.SET_RECV_ECOM_LIST_DISPLAY: return setRecvEcomListDisplay(state, action);
        case actionTypes.SET_RECV_ECOM_LIST_IS_PAID_FULL: return setRecvEcomListIsPaidFull(state, action);
        case actionTypes.SET_RECV_ECOM_LIST_QUERY: return setRecvEcomListQuery(state, action);
        case actionTypes.SET_ORDER_CUSTOM_PAGE: return setOrderCustomPage(state, action);
        case actionTypes.SET_ORDER_CUSTOM_METHOD: return setOrderCustomMethod(state, action);
        case actionTypes.SET_ORDER_CUSTOM_CATEGORY: return setOrderCustomCategory(state, action);
        case actionTypes.SET_ORDER_CUSTOM_FILTER: return setOrderCustomFilter(state, action);
        case actionTypes.SET_ORDER_CUSTOM_CART: return setOrderCustomCart(state, action);
        case actionTypes.SET_PAY_ECOM_LIST_PAGE: return setPayEcomListPage(state, action);
        case actionTypes.SET_PAY_ECOM_LIST_QUERY: return setPayEcomListQuery(state, action);
        case actionTypes.SET_PAY_ECOM_LIST_DISPLAY: return setPayEcomListDisplay(state, action);
        case actionTypes.SET_PAY_ECOM_LIST_STATUS: return setPayEcomListStatus(state, action);
        default: return state;
    };
};

export default reducer;