import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/base';

const initialState = {
    mailingListPage: 1,
    mailingListFilter: '',
    mailingListOrderBy: 'createdOn DESC',
    mailingListNumPerPage: 100,
    edmListPage: 1,
    edmListFilter: '',
    edmListOrderBy: 'createdOn DESC',
    edmListNumPerPage: 20,
};

const setMailingListPage = (state, action) => {
    return updateObject(state, {
        mailingListPage: action.data,
    });
};

const setMailingListFilter = (state, action) => {
    return updateObject(state, {
        mailingListFilter: action.data,
    });
};

const setMailingListOrderBy = (state, action) => {
    return updateObject(state, {
        mailingListOrderBy: action.data,
    });
};

const setMailingListNumPerPage = (state, action) => {
    return updateObject(state, {
        mailingListNumPerPage: action.data,
    });
};

const setEdmListPage = (state, action) => {
    return updateObject(state, {
        edmListPage: action.data,
    });
};

const setEdmListFilter = (state, action) => {
    return updateObject(state, {
        edmListFilter: action.data,
    });
};

const setEdmListOrderBy = (state, action) => {
    return updateObject(state, {
        edmListOrderBy: action.data,
    });
};

const setEdmListNumPerPage = (state, action) => {
    return updateObject(state, {
        edmListNumPerPage: action.data,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_MAILING_LIST_PAGE: return setMailingListPage(state, action);
        case actionTypes.SET_MAILING_LIST_FILTER: return setMailingListFilter(state, action);
        case actionTypes.SET_MAILING_LIST_ORDER_BY: return setMailingListOrderBy(state, action);
        case actionTypes.SET_MAILING_LIST_NUM_PER_PAGE: return setMailingListNumPerPage(state, action);
        case actionTypes.SET_EDM_LIST_PAGE: return setEdmListPage(state, action);
        case actionTypes.SET_EDM_LIST_FILTER: return setEdmListFilter(state, action);
        case actionTypes.SET_EDM_LIST_ORDER_BY: return setEdmListOrderBy(state, action);
        case actionTypes.SET_EDM_LIST_NUM_PER_PAGE: return setEdmListNumPerPage(state, action);
        default: return state;
    };
};

export default reducer;