import * as actionTypes from './actionTypes';

export const setMailingListPage = (data) => {
    return {
        type: actionTypes.SET_MAILING_LIST_PAGE,
        data: data,
    };
};

export const setMailingListFilter = (data) => {
    return {
        type: actionTypes.SET_MAILING_LIST_FILTER,
        data: data,
    };
};

export const setMailingListOrderBy = (data) => {
    return {
        type: actionTypes.SET_MAILING_LIST_ORDER_BY,
        data: data,
    };
};

export const setMailingListNumPerPage = (data) => {
    return {
        type: actionTypes.SET_MAILING_LIST_NUM_PER_PAGE,
        data: data,
    };
};

export const setEdmListPage = (data) => {
    return {
        type: actionTypes.SET_EDM_LIST_PAGE,
        data: data,
    };
};

export const setEdmListFilter = (data) => {
    return {
        type: actionTypes.SET_EDM_LIST_FILTER,
        data: data,
    };
};

export const setEdmListOrderBy = (data) => {
    return {
        type: actionTypes.SET_EDM_LIST_ORDER_BY,
        data: data,
    };
};

export const setEdmListNumPerPage = (data) => {
    return {
        type: actionTypes.SET_EDM_LIST_NUM_PER_PAGE,
        data: data,
    };
};
