import React from 'react';
import classes from './SpinnerFixed.module.css';

const spinner = (props) => {
    return (
        <div 
            className={ classes.Loader }
            style={{ display: props.show || props.show == null ? 'block' : 'none' }}
        >
        Loading...
    </div>
    );
};

export default spinner;