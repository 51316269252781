import * as actionTypes from './actionTypes';

export const setNewsListPage = (page) => {
    return {
        type: actionTypes.SET_NEWS_LIST_PAGE,
        page: page,
    };
};

export const setNewsListQuery = (data) => {
    return {
        type: actionTypes.SET_NEWS_LIST_QUERY,
        data: data
    };
};

export const setNewsListNumPerPage = (data) => {
    return {
        type: actionTypes.SET_NEWS_LIST_NUM_PER_PAGE,
        data: data
    };
};
